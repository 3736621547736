import { AREA, AREA_RELATIVE, BAR, HOVER_LOW_OPACITY } from "#Root/ui/ASChart/constants"
import { useChartDataContext } from "#Root/ui/ASChart/contexts/DataContext"
import { useChartGraphContext } from "#Root/ui/ASChart/contexts/GraphContext"
import { useChartHoverStateContext } from "#Root/ui/ASChart/contexts/HoverStateContext"

import { useHoverPlacement } from "./Hover/useHoverPlacement"

const HighlightDot = ({ dotSize = 6 }) => {
  const { xScale, yScale, colors, domain, renderer, width, canvasWidth, canvasHeight } =
    useChartGraphContext()
  const { visibleSeries } = useChartDataContext()
  const { dataIndex, hoveredLine } = useChartHoverStateContext()
  const { isVisible } = useHoverPlacement()

  // Ignore bar renderer, if by any chance this component is used in a bar chart
  if (renderer === BAR) {
    return null
  }

  // Don't show any dots if there's no highlighted data point
  if (!isVisible) {
    return null
  }

  const canvasXOffset = width - canvasWidth
  const canvasYOffset = canvasHeight

  xScale.domain(domain.x)
  yScale.domain(domain.y)

  return visibleSeries.map((serie) => {
    const color = colors(serie)
    const data = serie.data[isVisible ? dataIndex : serie.data.length - 1]
    const y = renderer === AREA || renderer === AREA_RELATIVE ? data.y1 : data.y

    // 0.5 is the half of border
    const left = xScale(data.x) + canvasXOffset + 0.5
    const top = yScale(y)

    // Hide the dot if the data-point is out of this view (happens due to zooming part of the series)
    if (top > canvasYOffset) {
      return null
    }

    return (
      <div
        data-testid="highlight-dot"
        className="absolute rounded-full pointer-events-none -translate-y-1/2 z-10 bg-white -translate-x-1/2 border"
        style={{
          width: dotSize,
          height: dotSize,
          borderColor: color,
          left: left,
          top: top,
          opacity: hoveredLine && hoveredLine != serie.id ? HOVER_LOW_OPACITY : 1,
        }}
        key={`dot-${serie.id}`}
      />
    )
  })
}

HighlightDot.propTypes = {
  dotSize: PropTypes.number,
  filled: PropTypes.bool,
}

export default HighlightDot
