export default class Traces {
  client

  constructor(client) {
    this.client = client
  }

  trace(params) {
    return this.client.post("/api/v2/tracing/trace", params)
  }

  performance_traces(params) {
    return this.client.post("/api/v2/tracing/traces/performance", params)
  }

  error_traces(params) {
    return this.client.post("/api/v2/tracing/traces/errors", params)
  }

  actions(params) {
    return this.client.post("/api/v2/tracing/actions", params)
  }
}
