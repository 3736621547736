import Link from "#Root/components/shared/link"
import { useRouter } from "#Root/hooks"

import { useChartGraphContext } from "../../contexts/GraphContext"
import { HoverContext } from "../../contexts/HoverContext"

const MarkerButton = () => {
  const { currentPathWithParams } = useRouter()
  const { date } = React.useContext(HoverContext)
  const { xScale, domain } = useChartGraphContext()

  xScale.domain(domain.x)

  return (
    <Link
      className="block c-button c-button--white c-button--xs w-full"
      href={currentPathWithParams({
        overlay: "customMarker",
        date: date?.toISOString(),
        startDate: xScale.domain()[0]?.toISOString(),
        endDate: xScale.domain()[1]?.toISOString(),
      })}
    >
      <i className="fa fa-fw fa-map-marker mr-1" /> Add marker
    </Link>
  )
}

MarkerButton.propTypes = {}

export default MarkerButton
