import { useContext } from "react"
import { useMutation } from "react-apollo"

import Overlay from "#Components/shared/overlay"
import { RouterContext } from "#Root/contexts/RouterContext"
import { Button } from "#Root/ui"
import withParamNavigator from "#Root/wrappers/param_navigator"

const QUERY = gql`
  mutation archiveTrigger($id: String!, $appId: String!) {
    archiveTrigger(id: $id, appId: $appId) {
      id
    }
  }
`

const TriggerArchive = ({ appId, triggerId, currentPathWithParams }) => {
  const router = useContext(RouterContext)
  const [error, setError] = React.useState(null)
  const [archiveTriggerMutation] = useMutation(QUERY)

  const archiveTrigger = async () => {
    await archiveTriggerMutation({
      variables: { appId, id: triggerId },
      awaitRefetchQueries: true,
      refetchQueries: ["TriggersQuery"],
    })
      .then(() => closeModal())
      .catch((error) => setError(error))
  }

  const closeModal = () => {
    const path = currentPathWithParams({
      overlay: null,
      triggerId: null,
      form: null,
    })
    router.navigate(path, { trigger: true })
  }

  return (
    <Overlay classNames="alerting small" title="Delete this trigger?" onClose={closeModal}>
      <section className="mod-overlay-form">
        {error && (
          <section>
            <p className="error">
              There was an error sending the query:
              <code>{error.message}</code>
            </p>
          </section>
        )}
        <section>
          <p>Deleting this trigger will close all related open alerts.</p>
        </section>
        <section className="flex space-x-3">
          <Button onClick={archiveTrigger} color="red">
            Yes, delete trigger
          </Button>
          <Button onClick={closeModal} color="white">
            Cancel
          </Button>
        </section>
      </section>
    </Overlay>
  )
}

TriggerArchive.propTypes = {
  appId: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
  currentPathWithParams: PropTypes.func.isRequired,
}

export default withParamNavigator(TriggerArchive)
