import { ErrorBoundary } from "@appsignal/react"
import PropTypes from "prop-types"
import React from "react"
import { HotkeysProvider } from "react-hotkeys-hook"
import { Portal } from "react-portal"

import { PlatformContext } from "#Hooks/usePlatformFeature"
import GlobalKeyboardShortcuts from "#Root/components/GlobalKeyboardShortcuts"
import { ChartSettingsProvider } from "#Root/contexts/ChartSettingsContext"
import { RestClientContext } from "#Root/contexts/RestClientContext"
import GlobalOverlays from "#Root/overlays/GlobalOverlays"
import ChartSettingsMenu from "#Root/ui/ChartSettingsMenu"
import { ReactQueryProvider } from "#Root/utils/react-query-client"
import RestApiClient from "#Root/utils/RestApiClient"
import Ident from "#Utils/Ident"

import { EnabledLabFeaturesContext } from "../contexts/EnabledLabFeaturesContext"
import { FormatterContext } from "../contexts/FormatterContext"
import MenuContextController, { MenuContext } from "../contexts/MenuContext"
import { RouterContext } from "../contexts/RouterContext"
import AppSwitcher from "./shared/AppSwitcher"
import ComponentMap from "./shared/component_map"
import ErrorBox from "./shared/error_box"
import Navigation from "./shared/navigation"
import OptionalLightbox from "./shared/optional_lightbox"
import OrganizationNavigation from "./shared/OrganizationNavigation"
import Search from "./shared/Search"

const queryParamsFromString = (string) => {
  let match
  const urlParams = {}

  if (!string) {
    return urlParams
  }
  const search = /([^&=]+)=?([^&]*)/g
  const decode = (s) => decodeURIComponent(s.replace(/\+/g, " "))

  // eslint-disable-next-line no-cond-assign
  while ((match = search.exec(string))) {
    urlParams[decode(match[1])] = decode(match[2])
  }

  return urlParams
}

export const mappedParams = ({ keysString, values, token }) => {
  const result = {}
  let key
  let keys = []

  if (keysString) {
    keys = keysString.split("|")
  }

  // Check if we have custom params (?foo=bar&baz=baa)
  // and if so, parse them
  if (keys.length < values.length) {
    result.rawParams = queryParamsFromString(values[values.length - 1])
    for (key in result.rawParams) {
      const val = result.rawParams[key]
      result[key] = val
    }
  }

  for (let index = 0; index < keys.length; index++) {
    key = keys[index]
    if (key.indexOf("=") > -1) {
      const keyWithValue = key.split("=")
      result[keyWithValue[0]] = keyWithValue[1]
    } else {
      result[key] = values[index]
    }
  }

  result.token = token
  return result
}

const App = ({
  formatters,
  enabledLabFeatures,
  platforms,
  router,
  appsignal,
  token,
  chartSettings,
  restClientSettings,
}) => {
  const [state, setState] = React.useState({
    action: "",
    namespace: "web",
    params: {},
  })

  React.useLayoutEffect(() => {
    const callback = (action_with_params, params) => {
      const action = action_with_params.split("||")[0]
      const action_names = action_with_params.split("||")[1]
      const mapped_params = mappedParams({
        keysString: action_names,
        values: params,
        token: token,
      })
      window._routerParams = mapped_params
      if (action === "native") {
        location.href = `/${params[0]}`
      } else {
        setState({ action, params: mapped_params })
      }
    }

    router.addCallback(callback)
    router.start()

    return () => {
      router.removeCallback(callback)
    }
  }, [router, token])

  React.useEffect(() => {
    Ident.trackAction(state.action)
  }, [state.action])

  const renderPortal = (div, component) => {
    const reactNavElement = document.getElementById(div)

    if (!reactNavElement) {
      return null
    }

    // Ensure the native Menu is removed
    reactNavElement.innerHTML = null

    // Create a new element to attach the React menu on
    const newContainer = document.createElement("div")
    newContainer.className = "h-full"
    reactNavElement.appendChild(newContainer)

    return <Portal node={newContainer}>{component}</Portal>
  }

  const renderSearch = () => {
    const component = <Search params={state.params} />
    return renderPortal("react-search", component)
  }

  const renderAppSwitcher = () => {
    const component = <AppSwitcher params={state.params} />
    return renderPortal("react-app-switcher", component)
  }

  const renderOrgMenu = () => {
    const component = <OrganizationNavigation params={state.params} />
    return renderPortal("react-nav", component)
  }

  const renderAppMenu = () => {
    const component = <Navigation params={state.params} activeComponent={state.action} />
    return renderPortal("react-nav", component)
  }

  const renderMenu = () => (
    <MenuContext.Consumer>
      {(value) => {
        if (value.menu === "default") return renderAppMenu()
        if (value.menu === "organization") return renderOrgMenu()
        return null
      }}
    </MenuContext.Consumer>
  )

  const renderChartSettingsMenu = () => {
    const component = <ChartSettingsMenu params={state.params} />
    return renderPortal("chart-settings-menu-slot", component)
  }

  const { action, params } = state
  const Component = ComponentMap[action]

  const restApiClient = new RestApiClient(restClientSettings.endpoint, restClientSettings.token)

  let component
  if (Component) {
    component = <Component {...{ formatters, enabledLabFeatures, platforms, router, params }} />
  } else {
    if (action) {
      console.error(`No component for action "${action}" registered.`)
    }
    component = <div></div>
  }

  return (
    <ErrorBoundary
      instance={appsignal}
      action={action}
      tags={{ appId: params?.appId }}
      override={(span, _) => {
        span.setParams(params)
        return span
      }}
      fallback={(error) => <ErrorBox error={error} />}
    >
      <RouterContext.Provider value={router}>
        <HotkeysProvider initiallyActiveScopes={["global"]}>
          <GlobalKeyboardShortcuts />
          <MenuContextController>
            <EnabledLabFeaturesContext.Provider value={enabledLabFeatures}>
              <PlatformContext.Provider value={platforms}>
                <ReactQueryProvider>
                  <RestClientContext.Provider value={restApiClient}>
                    <ChartSettingsProvider value={chartSettings}>
                      <FormatterContext.Provider value={formatters}>
                        <GlobalOverlays params={params} />
                        <OptionalLightbox params={params} />
                        <React.Suspense fallback={null}>{component}</React.Suspense>
                        {renderMenu()}
                        {renderAppSwitcher()}
                        {renderSearch()}
                        {renderChartSettingsMenu()}
                      </FormatterContext.Provider>
                    </ChartSettingsProvider>
                  </RestClientContext.Provider>
                </ReactQueryProvider>
              </PlatformContext.Provider>
            </EnabledLabFeaturesContext.Provider>
          </MenuContextController>
        </HotkeysProvider>
      </RouterContext.Provider>
    </ErrorBoundary>
  )
}

App.propTypes = {
  formatters: PropTypes.object,
  enabledLabFeatures: PropTypes.array,
  platforms: PropTypes.array,
  router: PropTypes.object,
  appsignal: PropTypes.object,
  token: PropTypes.string,
  chartSettings: PropTypes.shape({
    horizontalSupportLines: PropTypes.bool,
    verticalSupportLines: PropTypes.bool,
    colorPalette: PropTypes.string,
  }),
  restClientSettings: PropTypes.shape({
    endpoint: PropTypes.string,
    token: PropTypes.string,
  }),
}

export default App
