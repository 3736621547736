import PropTypes from "prop-types"
import React from "react"

import { findFieldsForMetricType } from "../../../../../utils/metric_keys"
import TooltipWithIcon from "../../../../shared/TooltipWithIcon"
import { areFieldsConfigured } from "../../Utils"

const MetricFormFieldDropdown = ({ onChange, metric, containsMeasurementFields }) => {
  const value = containsMeasurementFields ? "MEASUREMENT" : (areFieldsConfigured(metric.fields) ? metric.fields[0].field : "")

  const handleMetricFieldDropdownChange = ({ target: { value } }) => {
    onChange({ fields: findFieldsForMetricType(value) })
  }

  return (
    <div className="c-form" data-testid="MetricFormFieldDropdown">
      <label className="c-form__label" htmlFor="metric_field_type">
        Field type
        <TooltipWithIcon
          side="right"
          content={
            <>
              <p className="my-2">Select the metric type that was sent in the code. <a className="text-underline text-blue-200" href="https://docs.appsignal.com/metrics/custom.html#metric-types" target="_blank" rel="noopener noreferrer">Read the documentation</a>.</p>
            </>
          }
        />
      </label>

      <div className="c-form__element">
        <div className="c-select">
          <select
            id="metric_field_type"
            name="metric_field_type"
            className="c-select__input"
            value={value}
            onChange={handleMetricFieldDropdownChange}
          >
            <option value="" disabled>Select metric type</option>
            <option value="COUNTER">Counter</option>
            <option value="GAUGE">Gauge</option>
            <option value="MEASUREMENT">Measurement</option>
          </select>
        </div>
      </div>
    </div>
  )
}

MetricFormFieldDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  metric: PropTypes.object.isRequired,
  containsMeasurementFields: PropTypes.bool.isRequired
}

export default MetricFormFieldDropdown
