const LoadingSpinner = ({ message = "Loading data", className = "" }) => {
  const spinnerClassName = className ? `${className} loader` : "loader"

  return (
    <p className={spinnerClassName}>
      <img src="/assets/puff.svg" /> {message}
    </p>
  )
}

LoadingSpinner.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
}

export default LoadingSpinner
