const CheckBox = ({ checked = false, onChange, disabled = false }) => {
  const handleClick = () => {
    if (disabled) {
      return
    }
    onChange(!checked)
  }

  let checkboxClass = "c-checkbox mt-0 text-gray-500"
  let checkboxIconClass = "far fa-square fa-fw"

  if (checked === true) {
    checkboxClass = "c-checkbox mt-0 text-green-500"
    checkboxIconClass = "fas fa-check-square fa-fw"
  } else if (disabled === true) {
    checkboxClass = "c-checkbox mt-0 text-gray-300"
  }

  return (
    <div
      className={checkboxClass}
      onClick={handleClick}
      data-testid="checkbox"
      role="checkbox"
      aria-checked={checked}
    >
      <div className="c-checkbox__input mx-auto">
        <i className={checkboxIconClass} />
      </div>
    </div>
  )
}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default CheckBox
