import PropTypes from "prop-types"
import React from "react"
import TextareaAutosize from "react-textarea-autosize"

export const TextArea = React.forwardRef(({ autosize = true, ...props }, ref) => {
  const renderTextArea = () => {
    if (autosize) {
      return <TextareaAutosize className="c-textarea__input resize-y" {...props} ref={ref} />
    } else {
      return <textarea className="c-textarea__input resize-y" {...props} ref={ref} />
    }
  }

  return (
    <div className="c-form__element">
      <div className="c-textarea">{renderTextArea()}</div>
    </div>
  )
})

TextArea.displayName = "TextArea"

TextArea.propTypes = {
  autosize: PropTypes.bool,
}

export default TextArea
