import PropTypes from "prop-types"
import React from "react"

import TagsFormCombinations from "../../../shared/tags_form/Combinations"
import TagsFormCombinationWarning from "../../../shared/tags_form/CombinationWarning"
import TagsFormInputPairs from "../../../shared/tags_form/InputPairs"
import TooltipWithIcon from "../../../shared/TooltipWithIcon"

const MetricFormTags = ({ metric: { tags }, metricMetadata, onChange }) => {
  const childProps = {
    tags: tags || [],
    metricMetadata,
    onChange
  }
  return (
    <>
      <div className="c-form">
        <label className="c-form__label mb-3">
          Tags
          <TooltipWithIcon
            side="right"
            content={
              <>
                <p className="my-2">Select which tags of the metric should be display in the graph:</p>
                <ul className="list-disc list-inside">
                  <li className="pl-2 my-1">
                    <span className="-ml-1">When empty, all variations of a tag will create their own line in the graph.</span>
                  </li>
                  <li className="pl-2 my-1">
                    <span className="-ml-1">Enter tags to filter for certain tag values. In this case, all tags of the metric need to be specified, either with a value or a wildcard.</span>
                  </li>
                </ul>
                <hr className="ignore-old-css -mx-3 border-gray-700 my-2" />
                <p className="my-2"><a className="text-underline text-blue-200" href="https://docs.appsignal.com/metrics/custom.html#metric-tags" target="_blank" rel="noopener noreferrer">Read the documentation</a></p>
              </>
            }
          />
        </label>
        <div className="c-form__element">
          <TagsFormInputPairs {...childProps} />
          <TagsFormCombinationWarning {...childProps} />
          <TagsFormCombinations {...childProps} />
        </div>
      </div>
    </>
  )
}

MetricFormTags.propTypes = {
  onChange: PropTypes.func.isRequired,
  metric: PropTypes.object.isRequired,
  metricMetadata: PropTypes.object
}

export default MetricFormTags
