import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import { EnabledLabFeaturesContext } from "../../../contexts/EnabledLabFeaturesContext"
import { tagsToString } from "../../../utils/tags"
import { warmupAndCooldownPropType } from "../../../wrappers/warmup_and_cooldown"

const ShareLink = ({ trigger, form, warmupAndCooldown: { warmupDuration, cooldownDuration } }) => {
  const [isCopied, setCopied] = useState(false)
  const enabledLabFeatures = useContext(EnabledLabFeaturesContext)

  const url = () => {
    const params = {
      to: "triggers",
      overlay: "triggerForm",
      form,
      ...trigger,
      warmupDuration,
      cooldownDuration,
      tags: tagsToString(trigger.tags || [])
    }
    if (params.formatInput === null) {
      delete params.formatInput
    }
    const query = new URLSearchParams(params)
    return `https://appsignal.com/redirect-to/app?${query}`
  }

  const handleClick = (event) => {
    if (!event.metaKey && !event.ctrlKey && !event.shiftKey && event.button !== 1) {
      event.preventDefault()
      event.stopPropagation()
      // Copy to clipboard
      navigator.clipboard.writeText(url()).then(
        _ => setCopied(true),
        error => appsignal.sendError(error)
      )
    }
  }

  if (!enabledLabFeatures.includes("__internal.share_new_trigger_link")) {
    return <></>
  }

  return (
    <div className="py-6 text-center">
      <a
        href={url()}
        className="c-button c-button--gray"
        onClick={handleClick}
      >
        <i className="fa fa-fw fa-share mr-2" />
        Share&nbsp;
        <sup className="text-xs">AppSignal only</sup>
      </a>

      {isCopied && <div>Copied!</div>}
    </div>
  )
}

ShareLink.propTypes = {
  trigger: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  warmupAndCooldown: warmupAndCooldownPropType.isRequired
}

export default ShareLink
