import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import PropTypes from "prop-types"
import React from "react"
import DatePicker from "react-datepicker"
dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(utc)

import { useFormatterContext } from "#Root/contexts/FormatterContext"
import FormattedTime from "#Root/formatters/FormattedTime"

import CalendarContainer from "./CalendarContainer"
import DatePickerInputButton from "./DatePickerInputButton"

const TimestampSelector = ({
  onApplyCallback,
  currentValue,
  segmentedControl = true,
  customButtonIcon,
}) => {
  const datePickerRef = React.createRef()
  const [buttonLabel, setButtonLabel] = React.useState("Custom range")
  const { timeZone } = useFormatterContext()
  const [date, setDate] = React.useState(
    currentValue ? dayjs(currentValue) : dayjs().tz(timeZone).hour(0).minute(0).second(0),
  )

  const timestampRef = React.useRef(date.tz(timeZone).format("HH:mm:ss").split(" ")[0])

  React.useEffect(() => {
    if (currentValue) {
      timestampRef.current = dayjs(currentValue).tz(timeZone).format("HH:mm:ss").split(" ")[0]
      setButtonLabel(<FormattedTime value={currentValue} format="short" />)
    } else {
      setButtonLabel("Jump to date")
    }
  }, [currentValue, timeZone])

  const closeCalendar = () => datePickerRef.current.setOpen(false)

  const handleApply = () => {
    try {
      const timestamp = timestampRef.current.split(":")

      // The input is incomplete
      if (timestamp.length !== 3) {
        return
      }

      const newDate = date.tz(timeZone).hour(timestamp[0]).minute(timestamp[1]).second(timestamp[2])

      setDate(newDate)

      onApplyCallback(newDate)
      closeCalendar()
    } catch (error) {
      appsignal.sendError(error, (span) => {
        span.setParams({
          date: date,
          timestamp: timestampRef.current,
          tz: timeZone,
        })
      })

      closeCalendar()
    }
  }

  const handleDateChange = (date) => {
    setDate(dayjs(date))
  }

  return (
    <DatePicker
      key="date-picker"
      selected={date.toDate()}
      onChange={handleDateChange}
      previousMonthButtonLabel={<i className="far fa-fw fa-angle-left"></i>}
      nextMonthButtonLabel={<i className="far fa-fw fa-angle-right"></i>}
      popperClassName="z-40 !-top-[8px]"
      popperPlacement="top-start"
      shouldCloseOnSelect={false}
      ref={datePickerRef}
      customInput={
        <DatePickerInputButton
          label={buttonLabel}
          segmentedControl={segmentedControl}
          customIcon={customButtonIcon}
        />
      }
      autoFocus={true}
      calendarContainer={(props) => (
        <CalendarContainer
          closeCalendar={closeCalendar}
          handleApply={handleApply}
          timestampRef={timestampRef}
          {...props}
        />
      )}
    />
  )
}

TimestampSelector.propTypes = {
  onApplyCallback: PropTypes.func.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  segmentedControl: PropTypes.bool,
  customButtonIcon: PropTypes.node,
}

export default TimestampSelector
