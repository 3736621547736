import { scaleLinear, scaleUtc } from "d3-scale"

import { colorPaletteMap, useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import { useMeasure } from "#Root/hooks/useMeasure"

import {
  DEFAULT_CHART_HEIGHT,
  RELATIVE_RENDERS,
  STACKED_RENDERS,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from "../constants"
import { useChartDataContext } from "../contexts/DataContext"
import { DraggingProvider } from "../contexts/DraggingContext"
import { ChartFormattingProvider } from "../contexts/FormattingContext"
import { ChartGraphProvider } from "../contexts/GraphContext"
import { ChartHoverStateProvider } from "../contexts/HoverStateContext"
import { getChildrenMetadata } from "../utils/getChildrenMetadata"
import { applyColorPalette, getExtents, significantDecimalCountFor } from "../utils/util"
import Canvas from "./Canvas"
import EmptyState from "./EmptyState"
import ErrorState from "./ErrorState"
import LoadingState from "./LoadingState"
import { MarkersBase } from "./Markers"

const noop = () => undefined

const GraphBase = ({
  height = DEFAULT_CHART_HEIGHT,
  children,
  valueFormat = "number",
  valueInput = null,
  onClick,
  onItemHover = noop,
  getColorPalette,
  zoomEnabled = true,
}) => {
  const { disabledLines, timeseries, renderer } = useChartDataContext()
  const [measureRef, { width }] = useMeasure()
  const canvasRef = React.useRef()
  const [hoveredLine, toggleHoveredLine] = React.useState(null)
  const { settings } = useChartSettingsContext()

  const { hasCanvas, hasYAxis, hasXAxis, hasLockFeature, bodyChildren, footerChildren } =
    getChildrenMetadata(children)

  const onItemHoverHandler = (data) => {
    const values = []
    timeseries.series.forEach((serie) => {
      if (data?.dataIndex !== undefined) {
        values.push(serie.data[data.dataIndex].y)
      } else {
        values.push(serie.data[serie.data.length - 1].y)
      }
    })

    onItemHover(values)
  }

  React.useEffect(() => {
    onItemHoverHandler()
    // We're intentionally not including onItemHover in the dependencies array
    // Updating timeseries will unmount the parent component, so it's not a real concern
    // eslint-disable-next-line
  }, [])

  // Prepare the color generator
  let colors
  // Prioritize the function the user passed in
  if (typeof getColorPalette === "function") {
    colors = getColorPalette(timeseries)
  } else {
    const colorPalette = colorPaletteMap[settings.colorPalette]
    colors = applyColorPalette(timeseries.series, colorPalette)
  }

  const canvasWidth = hasYAxis ? width - Y_AXIS_WIDTH : width
  const canvasHeight = hasXAxis ? height - X_AXIS_HEIGHT : height

  const { xMin, xMax, yMin, yMax } = getExtents({
    timeseries,
    disabledLines,
    isStacked: STACKED_RENDERS.includes(renderer),
    isRelative: RELATIVE_RENDERS.includes(renderer),
  })

  const valueDifference = yMax - yMin
  const valuePrecision = significantDecimalCountFor(valueDifference)

  const yDomain = [yMin, yMax]
  const yScale = scaleLinear()
    .range([canvasHeight - 1, 5])
    .domain(yDomain)

  const xDomain = [new Date(xMin), new Date(xMax)]

  // Small padding for the last label to not be cut off
  const range = [0, canvasWidth - 20]
  const xScale = scaleUtc().range(range).domain(xDomain)

  return (
    <div className="mod-aschart" ref={measureRef}>
      <ChartGraphProvider
        {...{
          height,
          width,
          canvasWidth,
          canvasHeight,
          xScale,
          yScale,
          originalXDomain: xDomain,
          originalYDomain: yDomain,
          colors,
          renderer,
          onClick,
          hasYAxis,
          graphRef: measureRef,
          canvasRef,
          timeseries,
          zoomEnabled,
        }}
      >
        <ChartFormattingProvider
          valueFormat={valueFormat}
          valuePrecision={valuePrecision}
          valueInput={valueInput}
        >
          <ChartHoverStateProvider
            hoveredLine={hoveredLine}
            toggleHoveredLine={toggleHoveredLine}
            lockFeatureEnabled={hasLockFeature}
            onItemHover={onItemHoverHandler}
          >
            <DraggingProvider>
              <div style={{ height: `${height}px` }}>
                {bodyChildren}
                {!hasCanvas && <Canvas />}
              </div>
            </DraggingProvider>
            {footerChildren}
          </ChartHoverStateProvider>
        </ChartFormattingProvider>
      </ChartGraphProvider>
    </div>
  )
}

const Graph = ({ emptyRenderer, ...props }) => {
  const { timeseries, loading, error } = useChartDataContext()
  const { hasYAxis, hasMarkers } = getChildrenMetadata(props.children)

  const height = props.height || DEFAULT_CHART_HEIGHT

  if (loading) {
    return (
      <div>
        <LoadingState height={height} />
        {hasMarkers && <MarkersBase hasYAxis={hasYAxis} markers={[]} />}
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <ErrorState height={height} />
        {hasMarkers && <MarkersBase hasYAxis={hasYAxis} markers={[]} />}
      </div>
    )
  }

  if (!timeseries || timeseries?.series?.length === 0) {
    return typeof emptyRenderer === "function" ? (
      emptyRenderer({ height })
    ) : (
      <div>
        <EmptyState height={height} />
        {hasMarkers && <MarkersBase hasYAxis={hasYAxis} markers={[]} />}
      </div>
    )
  }

  return <GraphBase {...props} />
}

const GraphPropTypes = {
  height: PropTypes.number,
  children: PropTypes.node,
  valueFormat: PropTypes.oneOf(["number", "duration", "size", "throughput", "percent"]),
  valueInput: PropTypes.oneOf(["bit", "byte", "kilobit", "kilobyte"]),
  onClick: PropTypes.func,
  getColorPalette: PropTypes.func,
  onItemHover: PropTypes.func,
  emptyRenderer: PropTypes.func,
  zoomEnabled: PropTypes.bool,
}

GraphBase.propTypes = GraphPropTypes
Graph.propTypes = GraphPropTypes

Graph.displayName = "Graph"
export default Graph
