import URL_LIST from "../base/url_list"

const OPTIONALPARAM = /\((.*?)\)/g
const NAMEDPARAM = /(\(\?)?:\w+/g
const SPLATPARAM = /\*\w+/g

class Router {
  constructor(routes) {
    this.started = false
    this.routes = []
    this.callbacks = []

    if (!routes) {
      routes = {}
      for (const key in URL_LIST) {
        routes[key] = URL_LIST[key].controller
      }
    }

    // Map the given routes
    // and convert the key to a regex
    for (const route in routes) {
      const regex = this._routeToRegExp(route)
      this.routes.push({
        url: route,
        regex: regex,
        component: routes[route],
      })
      this.routes[regex] = routes[route]
    }
  }

  addCallback(callback) {
    this.callbacks.push(callback)
  }

  removeCallback(callback) {
    this.callbacks = this.callbacks.filter((cb) => cb !== callback)
  }

  callCallbacks(url, route) {
    for (const callback of this.callbacks) {
      callback(url, route)
    }
  }

  start() {
    if (this.started == true) {
      return
    }
    this.started = true

    window.addEventListener("popstate", () => {
      this.getAndCheckUrl()
    })
    this.getAndCheckUrl()
  }

  getUrl() {
    const path = window.location.pathname + window.location.search
    return path.charAt(0) === "/" ? path.slice(1) : path
  }

  parseUrlWithRoute(url, route) {
    return url.match(route.regex).slice(1)
  }

  getRouteFromUrl(url) {
    for (const route of this.routes) {
      if (route.regex.test(url)) {
        return route
      }
    }
    return null
  }

  getAndCheckUrl() {
    const url = this.getUrl()
    const route = this.getRouteFromUrl(url)
    if (route) {
      const params = this.parseUrlWithRoute(url, route)
      this.callCallbacks(route.component, params)
    }
  }

  navigate(url, options) {
    const trigger = options && options["trigger"] == true
    history.pushState({}, null, url)
    if (trigger) {
      this.getAndCheckUrl()
    }
  }

  // Modified version from BackboneJS
  // Original source:
  // https://github.com/jashkenas/backbone/blob/75e6d0ce6394bd2b809823c7f7dc014ddb6ae287/backbone.js#L1711-L1721
  _routeToRegExp(route) {
    route = route
      .replace(OPTIONALPARAM, "(?:$1)?")
      .replace(NAMEDPARAM, function (match, optional) {
        return optional ? match : "([^/?]+)"
      })
      .replace(SPLATPARAM, "([^?]*?)")
    return RegExp("^" + route + "(?:\\?([\\s\\S]*))?$")
  }
}

export default Router
