import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import IncidentNumber from "#Components/IncidentNew/IncidentNumber"
import Link from "#Components/shared/link"
import NamespaceLabel from "#Components/shared/namespace_label"
import UrlHelper from "#Components/shared/url_helper"
import Table, { columnHelper } from "#Root/ui/Table"
import DurationCell from "#Root/ui/Table/Cells/DurationCell"
import NumberCell from "#Root/ui/Table/Cells/NumberCell"
import Tooltip from "#Root/ui/Tooltip"
import { maxField } from "#Root/utils/table"

const QUERY = gql`
  query TimeDetectivePerformancePageQuery(
    $appId: String!
    $start: DateTime!
    $end: DateTime!
    $namespaces: [String]!
  ) {
    app(id: $appId) {
      id
      timeDetectivePerformanceDataPoints(start: $start, end: $end, namespaces: $namespaces) {
        namespace
        actionName
        mean
        p90
        throughput
        incident {
          ... on PerformanceIncident {
            id
            number
          }
        }
      }
    }
  }
`

const defaultColumns = [
  columnHelper.accessor("name", {
    header: "Name",
    size: 500,
    cell: ({
      row: {
        original: { actionName, incident, namespace, href },
      },
    }) => {
      const linkOrtooltip = actionName ? (
        <Link className="c-link" href={href} title={actionName} />
      ) : (
        <Tooltip>
          <Tooltip.Trigger>
            <span className="cursor-not-allowed c-link">{"<Unknown action name>"}</span>
          </Tooltip.Trigger>
          <Tooltip.Content>
            <div>
              <h2 className="c-heading">Unknown action name</h2>
              <p className="mt-2">
                We have received samples without an action name. Check your integration, and make
                sure an action name is set when creating a transaction.
              </p>
            </div>
          </Tooltip.Content>
        </Tooltip>
      )

      return (
        <div className="flex flex-col max-w-fit">
          <span>
            {linkOrtooltip} {incident && <IncidentNumber incident={incident} />}
          </span>
          <NamespaceLabel className="max-w-fit mt-1" namespace={namespace} />
        </div>
      )
    },
  }),

  columnHelper.accessor("mean", {
    header: "Mean",
    cell: ({ column, getValue, table }) => {
      return (
        <DurationCell
          columnId={column.id}
          value={getValue()}
          maxValues={table.options.meta.maxValues}
        />
      )
    },
  }),
  columnHelper.accessor("p90", {
    header: "90th Percentile",
    cell: ({ column, getValue, table }) => {
      return (
        <DurationCell
          columnId={column.id}
          value={getValue()}
          maxValues={table.options.meta.maxValues}
        />
      )
    },
  }),

  columnHelper.accessor("throughput", {
    header: "Throughput",
    cell: ({ getValue, column, table }) => (
      <NumberCell
        columnId={column.id}
        value={getValue()}
        maxValues={table.options.meta.maxValues}
      />
    ),
  }),
]

const TimeDetectivePerformancePage = ({ params, namespaces }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      appId: params.appId,
      start: params.td_start,
      end: params.td_end,
      namespaces: namespaces,
    },
  })

  const points = (data?.app?.timeDetectivePerformanceDataPoints || []).map(
    ({ incident, ...metric }) => {
      let href, id
      if (incident) {
        href = UrlHelper.performanceIncidentSamplesPagePath({
          number: incident.number,
          time: params.td_start,
        })
        id = incident.id
      } else {
        href = UrlHelper.actionShowPath({
          action_name: metric.actionName,
          namespace: metric.namespace,
        })
        id = `${metric.actionName}${metric.namespace}`
      }

      return { incident, href, id, ...metric }
    },
  )
  const maxValues = {
    throughput: maxField(points, "throughput"),
    mean: maxField(points, "mean"),
    p90: maxField(points, "p90"),
  }

  return (
    <>
      <Table
        columns={defaultColumns}
        data={points}
        defaultSorting={{ id: "throughput", desc: true }}
        loading={loading}
        error={error}
        header={<Table.Header title="Performance incidents in this timeframe" />}
        metaData={{ maxValues: maxValues }}
        fixed
      >
        <Table.Headers />
        <Table.Loading />
        <Table.Errors />
        <Table.Empty />
        <Table.VirtualizedRows narrow />
      </Table>
    </>
  )
}

TimeDetectivePerformancePage.propTypes = {
  params: PropTypes.object.isRequired,
  namespaces: PropTypes.array.isRequired,
}

export default TimeDetectivePerformancePage
