import { useOnClickOutside } from "#Root/hooks"
import cn from "#Root/utils/cn"

export const DropdownContext = React.createContext()

const Dropdown = ({ select = "single", closeOnSelect = true, children, onClose }) => {
  const [menuOpen, setMenuOpenState] = React.useState(false)
  const dropdownRef = React.useRef()

  const setMenuOpen = (newState) => {
    setMenuOpenState(newState)

    if (newState == false && onClose !== undefined) {
      onClose()
    }
  }

  return (
    <DropdownContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        closeOnSelect,
        selectType: select,
        dropdownRef,
        onButtonClick: () => setMenuOpen(!menuOpen),
      }}
    >
      <div className="relative" ref={dropdownRef}>
        {children}
      </div>
    </DropdownContext.Provider>
  )
}

Dropdown.propTypes = {
  children: PropTypes.node,
  select: PropTypes.oneOf(["none", "single", "multiple"]),
  closeOnSelect: PropTypes.bool,
  onClose: PropTypes.func,
}

const UnstyledDropdownButton = ({ children, className, onClick }) => {
  const { menuOpen } = React.useContext(DropdownContext)

  return (
    <DropdownTrigger onClick={onClick}>
      <button className={cn(className, { active: menuOpen })}>{children}</button>
    </DropdownTrigger>
  )
}

UnstyledDropdownButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

const DropdownButton = ({
  style = "default",
  customIcon,
  children,
  iconPosition = "left",
  hideCaret = false,
  addClassName,
  ...otherProps
}) => {
  const icon = customIcon ? customIcon : null
  const label = style !== "minimal" ? <span>{children}</span> : null
  const caret =
    style === "default" ? <i className="far fa-angle-down c-dropdown__item-icon" /> : null

  const classNames = [
    "c-button c-button--white font-normal ignore-old-css focus:outline-none",
    addClassName,
    style === "default" ? "c-button--sm" : "c-button--xs",
  ]
  return (
    <DropdownTrigger>
      <button className={classNames.filter((c) => c).join(" ")} {...otherProps}>
        {iconPosition === "left" && icon}
        {label}
        {iconPosition === "right" && icon}
        {!hideCaret && caret}
      </button>
    </DropdownTrigger>
  )
}

DropdownButton.propTypes = {
  children: PropTypes.node,
  customIcon: PropTypes.node,
  style: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  hideCaret: PropTypes.bool,
  addClassName: PropTypes.string,
}

const DropdownTrigger = ({ children, onClick }) => {
  const { onButtonClick, menuOpen } = React.useContext(DropdownContext)
  const childAttr = typeof children === "function" ? children(menuOpen) : children
  const newChildren = React.Children.map(childAttr, (child) => {
    return React.cloneElement(child, {
      onClick: () => {
        if (onClick) {
          onClick()
        }
        onButtonClick()
      },
    })
  })
  return <React.Fragment>{newChildren}</React.Fragment>
}

DropdownTrigger.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClick: PropTypes.func,
}

const DropdownItems = ({ align = "right", width = "w-64", children, className }) => {
  const { menuOpen, setMenuOpen, dropdownRef } = React.useContext(DropdownContext)

  // Only attach it when open, to avoid unnecessary event listeners
  useOnClickOutside(dropdownRef, () => setMenuOpen(false), { enabled: menuOpen })

  const alignmentClass = align === "right" ? "right-0" : "left-0"
  if (!menuOpen) return null

  return (
    <div className={`c-dropdown ${width} absolute ${alignmentClass} ${className} z-40`}>
      {children}
    </div>
  )
}

DropdownItems.propTypes = {
  children: PropTypes.node,
  align: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
}

const DropdownSearch = ({ filter, setFilter, placeholder }) => {
  return (
    <div className="flex items-center border border-gray-300 rounded m-2">
      <i className="text-gray-600 far fa-search pl-2" />
      <input
        type="text"
        placeholder={placeholder}
        className="!border-none !shadow-none !text-gray-900"
        autoFocus
        onChange={(e) => setFilter(e.target.value)}
        value={filter}
      />
      {filter && (
        <button
          className="text-blue-500 font-semibold h-5 w-5 mr-2 shrink-0 flex items-center justify-center rounded-full hover:bg-gray-100 active:bg-gray-200"
          onClick={() => setFilter("")}
        >
          &#10005;
        </button>
      )}
    </div>
  )
}

DropdownSearch.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  placeholder: PropTypes.string,
}

const DropdownTitle = ({ children }) => {
  return <div className="c-dropdown__title">{children}</div>
}

DropdownTitle.propTypes = {
  children: PropTypes.node,
}

const DropdownItem = ({
  onSelect,
  value,
  selected = false,
  locked,
  children,
  large = false,
  customIcon,
  extraClassName,
}) => {
  const { setMenuOpen, selectType, closeOnSelect } = React.useContext(DropdownContext)

  const renderIcon = () => {
    if (customIcon) return customIcon
    if (selectType === "none") return null

    const iconClassName = () => {
      if (selectType === "multiple") {
        return selected ? "fa fa-check-square" : "far fa-square"
      } else {
        return selected ? "fa fa-check-circle" : "far fa-circle"
      }
    }

    const activeClassName = selected ? "text-blue-500" : ""
    return <i className={`${iconClassName()} c-dropdown__item-icon ${activeClassName}`} />
  }

  const activeClassName = selected ? "c-dropdown__item--active" : ""
  const sizeClassName = large ? "c-dropdown__item--lg" : ""

  const handleSelect = (value, event) => {
    if (closeOnSelect) setMenuOpen(false)

    if (onSelect) {
      onSelect(value, event.metaKey || event.altKey)
    }
  }

  return (
    <div
      className={`c-dropdown__item ${sizeClassName} ${activeClassName} ${extraClassName ?? ""} ${
        locked ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={(event) => handleSelect(value, event)}
    >
      {renderIcon()}
      <span className="c-dropdown__item-label">{children}</span>
      {customIcon && selected && <i className="far fa-check c-dropdown__item-icon text-blue-500" />}
    </div>
  )
}

DropdownItem.propTypes = {
  children: PropTypes.node,
  onSelect: PropTypes.func,
  value: PropTypes.string,
  selected: PropTypes.bool,
  locked: PropTypes.bool,
  large: PropTypes.bool,
  customIcon: PropTypes.node,
  extraClassName: PropTypes.string,
}

const DropdownDivider = () => {
  return <hr className="c-dropdown__divider ignore-old-css" />
}

Dropdown.Button = DropdownButton
Dropdown.PlainButton = UnstyledDropdownButton
Dropdown.Trigger = DropdownTrigger
Dropdown.Items = DropdownItems
Dropdown.Title = DropdownTitle
Dropdown.Item = DropdownItem
Dropdown.Search = DropdownSearch
Dropdown.Divider = DropdownDivider

export default Dropdown
