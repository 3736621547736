import * as RadixTooltip from "@radix-ui/react-tooltip"

const Tooltip = ({ delayDuration = 200, children }) => {
  return (
    <RadixTooltip.Provider delayDuration={delayDuration}>
      <RadixTooltip.Root>{children}</RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}

Tooltip.propTypes = {
  delayDuration: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export const Trigger = ({ children }) => {
  return <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
}

Trigger.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export const Content = ({ side, children }) => {
  return (
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        className="bg-gray-900 text-white font-normal rounded shadow-sm px-3 py-1 z-[9999] max-w-80 ignore-react-onclickoutside"
        sideOffset={5}
        side={side || "top"}
      >
        {children}
        <RadixTooltip.Arrow />
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  )
}

Content.propTypes = {
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Tooltip.Trigger = Trigger
Tooltip.Content = Content

export default Tooltip
