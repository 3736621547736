import Tippy from "@tippyjs/react"

const Tooltip = (props) => {
  const {
    placement = "right",
    appendTo = document.body,
    interactive = true,
    popperOptions = {
      strategy: "fixed",
    },
    ...rest
  } = props

  return (
    <Tippy
      placement={placement}
      appendTo={appendTo}
      interactive={interactive}
      popperOptions={popperOptions}
      {...rest}
      content={<div className="ignore-react-onclickoutside break-words">{props.content}</div>}
    >
      {props.children}
    </Tippy>
  )
}

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.node,
  //
  placement: PropTypes.string,
  appendTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  interactive: PropTypes.bool,
  popperOptions: PropTypes.object,
}

export default Tooltip
