import Tooltip from "#Root/ui/Tooltip"

const TooltipWithIcon = ({ close = false, content, side }) => (
  <Tooltip>
    <Tooltip.Trigger>
      <i
        className={`${close ? "ml-1" : "ml-2"} text-gray-600 text-ms align-baseline cursor-pointer fa fa-info-circle`}
      ></i>
    </Tooltip.Trigger>
    <Tooltip.Content side={side}>{content}</Tooltip.Content>
  </Tooltip >

)

TooltipWithIcon.propTypes = {
  close: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  side: PropTypes.oneOf(["top", "bottom", "left", "right"])
}

export default TooltipWithIcon
