import PropTypes from "prop-types"
import React from "react"

import TooltipWithIcon from "../../../shared/TooltipWithIcon"

const VisualFormDrawNullAsZero = ({ visual: { drawNullAsZero }, onChange }) => {
  return (
    <div className="c-form">
      <label className="c-form__label mb-1">
        NULL values
        <TooltipWithIcon
          side={"right"}
          content={
            <>
              <p className="my-2">
                Metrics do not always have a value for every point in time. These missing values are
                NULL values. They can be drawn in two ways:
              </p>
              <ul className="list-disc list-inside">
                <li className="pl-2 my-1">
                  <span className="-ml-1">Draw NULL value as 0</span>
                </li>
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    Repeat the last known value until a later point in time specifies a new value
                  </span>
                </li>
              </ul>
              <hr className="ignore-old-css -mx-3 border-gray-700 my-2" />
              <p className="my-2">
                See example in{" "}
                <a
                  className="text-underline text-blue-200"
                  href="https://docs.appsignal.com/metrics/custom-metrics/dashboards.html#null-values"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  documentation
                </a>
              </p>
            </>
          }
        />
      </label>
      <div className="c-form__element">
        <div className="c-radio">
          <input
            type="radio"
            id="visual-draw-null-as-zero-true"
            name="visual-draw-null-as-zero-true"
            className="c-radio__input"
            value={true}
            checked={drawNullAsZero === true}
            onChange={(_) => onChange({ drawNullAsZero: true })}
          />
          <label className="ignore-old-css" htmlFor="visual-draw-null-as-zero-true">
            Draw NULL as 0
          </label>
        </div>
        <div className="c-radio">
          <input
            type="radio"
            id="visual-draw-null-as-zero-false"
            name="visual-draw-null-as-zero-false"
            className="c-radio__input"
            value={false}
            checked={drawNullAsZero === false}
            onChange={(_) => onChange({ drawNullAsZero: false })}
          />
          <label className="ignore-old-css" htmlFor="visual-draw-null-as-zero-false">
            Repeat last known value
          </label>
        </div>
      </div>
    </div>
  )
}

VisualFormDrawNullAsZero.propTypes = {
  visual: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default VisualFormDrawNullAsZero
