import { useState } from "react"
import { useQuery } from "react-apollo"

import PaginatedMetricsListQuery from "#Graphql/PaginatedMetricsListQuery"

export const LIMIT = 50

/**
 * Fetches all data for a page, in parallel batches
 * @param  {Query} query The GraphQL query to execute
 * @param  {Object} variables The variables to pass to the query
 * @param  {Function} getTotal Function that can be called on the result to return the total count
 * @param  {Function} updateQuery Function called to update the Apollo cache
 * @return {{
 *  pagination: {
 *    totalRows: Integer,
 *    currentRow: Integer,
 *    totalPages: Integer,
 *    currentPage: Integer,
 *    paginated: Bool
 *  },
 *  data: Object,
 *  error: Object,
 *  loading: Bool
 * }}  An object, exposing the following variables
 * @return {pagination} An object exposing total pages/rows being fetched and the current pages/rows that's done fetching
 * @return {data: Object} The data object, from the query
 * @return {error: Object} The query error, if any
 * @return {loading: Bool} A boolean, indicating if any of the batches are still loading
 */
export const usePaginatedQuery = (query, variables, getTotal, updateQuery) => {
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({ paginated: false })
  const { data, error, fetchMore, ...rest } = useQuery(query, {
    variables: { ...variables, offset: 0, limit: LIMIT },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      const total = getTotal(res)
      if (total > LIMIT) {
        const pages = Math.ceil((total - LIMIT) / LIMIT)
        setPagination({
          paginated: true,
          totalPages: pages + 1, // Total should include the one we just fetched
          currentPage: 1,
          totalRows: total,
          currentRow: LIMIT,
        })
        fetchAll(pages)
      } else {
        setLoading(false)
      }
    },
  })

  const fetchAll = async (pages) => {
    const promises = Array(pages)
      .fill()
      .map(async (_, idx) => {
        // Add a try-catch block, as the `fetchMore` queries are not cancelled.
        // So if the component unmounts, the promise is still pending and tries to update a non-existing `ObservableQuery`
        // https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099
        try {
          await fetchMore({
            variables: {
              offset: (idx + 1) * LIMIT,
              limit: LIMIT,
            },
            updateQuery: (prev, results) => updateQuery(prev, results),
          })
          // The currentRow will overflow on the last call,
          // if the count is not exactly the limit,
          // this doesn't matter, though, as by then the
          // Promise.all will be resolved, removing the loading state
          // and hiding the count all together.
          setPagination((pagination) => ({
            ...pagination,
            currentPage: (pagination.currentPage || 0) + 1,
            currentRow: pagination.currentRow + LIMIT,
          }))
        } catch {
          // Do nothing, as the component is unmounted
        }
      })
    Promise.all(promises).then((_) => {
      setLoading(false)
    })
  }

  return { ...rest, pagination, data, error, loading }
}

/**
 * Convenience method for all paginated metric lists
 * @param  {Object} variables The variables to pass to the query
 * @return {Function} Calls usePaginatedQuery, see above for return values
 */
export const usePaginatedMetricsListQuery = (variables) => {
  return usePaginatedQuery(
    PaginatedMetricsListQuery,
    variables,
    (res) => res?.app.metrics.list.total || 0,
    (prev, { fetchMoreResult }) => {
      const rows = [
        ...(prev?.app?.metrics?.list?.rows || []),
        ...fetchMoreResult.app.metrics.list.rows,
      ]
      const result = Object.assign({}, prev, {
        app: {
          __typename: "App",
          id: fetchMoreResult.app.id,
          metrics: {
            __typename: "Metrics",
            list: {
              __typename: "AggregationList",
              start: prev.app.metrics.list.start,
              end: prev.app.metrics.list.end,
              rows: rows,
              total: prev.app.metrics.list.total,
            },
          },
        },
      })
      return result
    },
  )
}
