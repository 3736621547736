import BarChart from "#Components/shared/bar_chart"
import FormattedNumber from "#Root/formatters/FormattedNumber"

const NumberCell = ({ columnId, value, maxValues }) => {
  return (
    <div className="space-y-1">
      <FormattedNumber value={value} />
      <BarChart max={maxValues[columnId]} val={value} />
    </div>
  )
}

NumberCell.propTypes = {
  columnId: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  maxValues: PropTypes.object.isRequired,
}

export default NumberCell
