import compose from "lodash.flowright"
import { graphql } from "react-apollo"

import { RouterContext } from "#Root/contexts/RouterContext"
import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import CreateTriggerMutation from "../../graphql/create_trigger_mutation"
import TriggerFormQuery from "../../graphql/trigger_form_query"
import Ident from "../../utils/Ident"
import withParamNavigator from "../../wrappers/param_navigator"
import ErrorBox from "../shared/error_box"
import Overlay from "../shared/overlay"
import TriggerFormCustomMetrics from "./form/CustomMetrics"
import TriggerFormExceptionCount from "./form/exception_count"
import TriggerFormExceptionRate from "./form/exception_rate"
import TriggerFormHostCPUUsage from "./form/host/cpu_usage"
import TriggerFormHostDiskIO from "./form/host/disk_io"
import TriggerFormHostDiskUsage from "./form/host/disk_usage"
import TriggerFormHostLoadAverage from "./form/host/load_average"
import TriggerFormHostMemoryUsage from "./form/host/memory_usage"
import TriggerFormHostNetworkTraffic from "./form/host/network_traffic"
import TriggerFormHostSwapUsage from "./form/host/swap_usage"
import TriggerFormPerformance from "./form/performance"
import TriggerFormQueueTime from "./form/queue_time"
import TriggerFormSidebar from "./form/sidebar"
import TriggerFormThroughput from "./form/throughput"
import { getDataFrom, writeDataTo } from "./store_helpers"

export class NewTrigger extends React.Component {
  static contextType = RouterContext

  static propTypes = {
    rawParams: PropTypes.object.isRequired,
    triggerId: PropTypes.string,
    appId: PropTypes.string.isRequired,
    step: PropTypes.string,
    form: PropTypes.string,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      app: PropTypes.object,
    }).isRequired,
    currentPathWithParams: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
  }

  state = {}

  handleOverlayClose = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.context.navigate(this.closePath(), { trigger: true })
  }

  handleClose = (event) => {
    Ident.trackAction("TriggerFormClose")
    this.handleOverlayClose(event)
  }

  closePath = () => {
    return this.props.currentPathWithParams({
      overlay: null,
      triggerId: null,
      form: null,
      namespace: null,
      hostname: null,
      state: null,
      mountpoint: null,
      name: null,
      metricName: null,
      field: null,
      tags: null,
      noMatchIsZero: null,
      conditionValue: null,
      comparisonOperator: null,
      warmupDuration: null,
      cooldownDuration: null,
    })
  }

  handleCreateOrUpdateTrigger = (variables) => {
    const {
      appId,
      data: {
        app: { trigger },
      },
    } = this.props
    // Set the id if a trigger has been given in props (edit record)
    let previousTriggerId = ""
    let kind = this.props.form || "ExceptionRate"
    if (trigger) {
      previousTriggerId = trigger.id
      kind = trigger.kind
    }
    this.props
      .mutate({
        variables: Object.assign(
          { appId: appId, previousTriggerId: previousTriggerId, kind: kind },
          variables,
        ),
        update: (store, { data: { createTrigger } }) => {
          const data = getDataFrom(store, appId)

          // Remove previous trigger from store
          if (previousTriggerId) {
            data.app.triggers = data.app.triggers.filter(function (trigger) {
              return trigger.id != previousTriggerId
            })
          }

          // Add new trigger to store
          data.app.triggers.push(createTrigger)
          writeDataTo(store, appId, data)
        },
      })
      .then(() => {
        const metric = trigger ? "TriggerFormUpdate" : "TriggerFormCreate"
        Ident.trackAction(metric)
        this.handleOverlayClose()
      })
      .catch((error) => {
        // If it fails, log the error (needs something better probably)
        this.setState({ mutationError: error })
      })
  }

  render() {
    const {
      appId,
      data: { loading, error, app },
    } = this.props
    let form = this.props.form || "ExceptionRate"
    let el
    let trigger = null
    let title = "New trigger"

    // If we're loading data for the first time, show loading box
    if (loading && !app) {
      title = "Loading trigger"
      el = <LoadingAnimation message="Loading form..." />
    }

    // If we encounter an error, show it
    else if (error) {
      title = "Error loading trigger"
      el = <ErrorBox error={error} />
    }

    // Otherwise render the form
    else {
      let blankSlate = false
      let formComponent
      if (app && app.trigger) {
        title = "Update trigger"
        trigger = app.trigger
        form = trigger.kind
      }

      if (this.props.step == "new") {
        blankSlate = true
        title = "Create your first trigger"
      }

      let errorMessage
      if (this.state.mutationError) {
        errorMessage = (
          <ErrorBox>
            There was an error sending the query:
            <code>{this.state.mutationError.message}</code>
          </ErrorBox>
        )
      }

      const params = this.props.rawParams
      const args = {
        params,
        onClose: this.handleClose,
        namespaces: app.namespaces,
        appId,
        blankSlate,
        trigger,
        onChange: this.handleCreateOrUpdateTrigger,
        availableNotifiers: app.notifiers || [],
        dashboards: app.dashboards || [],
        error: errorMessage,
      }

      switch (form) {
        case "ExceptionRate":
          formComponent = <TriggerFormExceptionRate {...args} />
          break
        case "ExceptionCount":
          // triggerConstructor = exceptionCountConstructor
          formComponent = <TriggerFormExceptionCount {...args} />
          break
        case "Throughput":
          formComponent = <TriggerFormThroughput {...args} />
          break
        case "Performance":
          formComponent = <TriggerFormPerformance {...args} />
          break
        case "QueueTime":
          formComponent = <TriggerFormQueueTime {...args} />
          break
        case "HostDiskIO":
          formComponent = <TriggerFormHostDiskIO {...args} />
          break
        case "HostDiskUsage":
          formComponent = <TriggerFormHostDiskUsage {...args} />
          break
        case "HostLoadAverage":
          formComponent = <TriggerFormHostLoadAverage {...args} />
          break
        case "HostMemoryUsage":
          formComponent = <TriggerFormHostMemoryUsage {...args} />
          break
        case "HostSwapUsage":
          formComponent = <TriggerFormHostSwapUsage {...args} />
          break
        case "HostNetworkTraffic":
          formComponent = <TriggerFormHostNetworkTraffic {...args} />
          break
        case "HostCPUUsage":
          formComponent = <TriggerFormHostCPUUsage {...args} />
          break
        case "Advanced":
          formComponent = <TriggerFormCustomMetrics {...args} />
          break
      }

      el = (
        <section className="c-box mb-0 flex relative h-full">
          {!trigger && (
            <div className="absolute left-0 top-0 bottom-0 w-64 overflow-y-auto border-r border-gray-200">
              <div className="sticky top-0 bg-white z-10 px-5 py-4 border-b border-gray-200">
                <h2 className="c-heading text-base mb-0 text-gray-800">Pick a metric</h2>
              </div>
              <div className="p-5">
                <TriggerFormSidebar currentForm={form} />
              </div>
            </div>
          )}
          <div className={`flex relative h-full overflow-y-auto ${!trigger ? "ml-64" : ""}`}>
            {formComponent}
          </div>
        </section>
      )
    }

    return (
      <Overlay
        classNames="alerting"
        innerClassName={trigger ? "max-w-screen-xl" : "max-w-screen-2xl"}
        title={title}
        onClose={this.handleOverlayClose}
      >
        {el}
      </Overlay>
    )
  }
}

export default compose(
  graphql(TriggerFormQuery, {
    options: ({ appId, triggerId }) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        appId: appId,
        triggerId: triggerId,
      },
    }),
  }),
  graphql(CreateTriggerMutation),
  withParamNavigator,
)(NewTrigger)
