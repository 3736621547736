const CopyTextButton = ({ value, text = "Copy value" }) => {
  const [buttonText, setButtonText] = React.useState(text)

  const copyToClipboard = () => {
    setButtonText("Copied!")
    navigator.clipboard.writeText(value)

    setTimeout(() => {
      setButtonText(text)
    }, 2000)
  }

  return (
    <button onClick={copyToClipboard} className="c-link">
      {buttonText}
    </button>
  )
}

CopyTextButton.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default CopyTextButton
