import { useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import { Button } from "#Root/ui"

import Overlay from "../../components/shared/overlay"
import { RouterContext } from "../../contexts/RouterContext"
import ExportDashboardQuery from "../../graphql/export_dashboard_query"
import { cleanObject, decamelizeObject } from "../../utils/object"
import withParamNavigator from "../../wrappers/param_navigator"

export function cleanupDashboard(dashboard) {
  const decamelizedObject = decamelizeObject(dashboard)
  const cleanDashboard = cleanObject(
    decamelizedObject,
    ["__typename", "id", "graphs"],
    [null, undefined],
  )
  cleanDashboard.visuals = (cleanDashboard.visuals || []).map((visual) => cleanupVisual(visual))

  return cleanDashboard
}

export function cleanupVisual(visual) {
  switch (visual.__typename) {
    case "VisualTimeseries":
      visual.type = "timeseries"
      break
    default:
      appsignal.sendError(
        new Error(
          "InvalidVisualType",
          "Someone tried to export visuals with an unknown type, " +
            "we need to update the `ExportOverlay` to handle this type.",
        ),
      )
      return null
  }

  const decamelizedVisual = decamelizeObject(visual, true)
  return cleanObject(decamelizedVisual, ["__typename", "id", "graphs"], [null, undefined], true)
}

export const DashboardExportOverlay = ({ currentPathWithParams, appId, id }) => {
  let value = "Loading..."
  const router = useContext(RouterContext)
  const { data, loading, error } = useQuery(ExportDashboardQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      appId: appId,
      id: id,
    },
  })

  const handleClose = () => {
    router.navigate(currentPathWithParams({ overlay: null }), { trigger: true })
  }

  if (error) {
    value = `Error: ${error}`
  } else if (!loading) {
    value = JSON.stringify(cleanupDashboard(data.app.dashboard), null, 2)
  }

  return (
    <Overlay classNames="medium" onClose={handleClose} title="Export dashboard as JSON">
      <div className="c-box text-gray-800">
        <div className="c-box__header">
          <h2>Dashboard JSON</h2>
        </div>
        <div className="p-5">
          <div className="c-textarea">
            <textarea className="c-textarea__input" rows="16" value={value} readOnly></textarea>
          </div>
        </div>
        <div className="px-5 py-4 border-t border-gray-200">
          <Button color="white" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </Overlay>
  )
}

DashboardExportOverlay.propTypes = {
  currentPathWithParams: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default withParamNavigator(DashboardExportOverlay)
