import { useMutation, useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import { Button } from "#Root/ui"

import { RouterContext } from "../../../contexts/RouterContext"
import CustomMetricsDashboardsDefinitionQuery from "../../../graphql/custom_metrics_dashboards_definition_query"
import DuplicateVisualMutation from "../../../graphql/duplicate_visual_mutation"
import withParamNavigator from "../../../wrappers/param_navigator"
import Overlay from "../overlay"
import UrlHelper from "../url_helper"
import ValidationErrors from "../validation_errors"
import AppSelector from "./AppSelector"
import DashboardSelector from "./DashboardSelector"

export const VisualDuplicateOverlay = ({
  currentPathWithParams,
  organizationSlug,
  appId,
  visualId,
}) => {
  const [error, setError] = useState(null)
  const router = useContext(RouterContext)
  const handleClose = () => {
    router.navigate(currentPathWithParams({ overlay: null }), { trigger: true })
  }

  const { data, loading } = useQuery(CustomMetricsDashboardsDefinitionQuery, {
    variables: { appId },
  })
  let requiredMetrics = []
  if (!loading) {
    data.app.dashboards.forEach((dashboard) => {
      return dashboard.visuals.forEach((visual) => {
        if (visual.id === visualId) {
          requiredMetrics = visual.metrics.map((metric) => metric.name)
        }
      })
    })
  }
  const renderRequiredMetrics = (requiredMetrics) => {
    const requiredMetricsList = requiredMetrics.map((metric) => {
      return <li key={`metric_${metric}`}>{metric}</li>
    })
    return (
      <>
        <p className="c-p--sm mt-3 mb-2">Please make sure the app has the following metrics:</p>
        <ul className="c-ul">{requiredMetricsList}</ul>
      </>
    )
  }

  const [selectedOrganizationSlug, setOrganizationSlug] = useState(organizationSlug)
  const [selectedAppId, setAppId] = useState(appId)
  const [selectedDashboardId, setDashboardId] = useState(null)

  const setOrgAndApp = ({ organizationSlug, appId }) => {
    setDashboardId(null)
    setOrganizationSlug(organizationSlug)
    setAppId(appId)
  }

  const [duplicate] = useMutation(DuplicateVisualMutation)
  const handleDuplicate = () => {
    duplicate({
      variables: {
        appId,
        visualId,
        newAppId: selectedAppId,
        newDashboardId: selectedDashboardId,
      },
    })
      .then(
        ({
          data: {
            duplicateVisual: { id },
          },
        }) => {
          window.location.assign(
            UrlHelper.metricsShowPath({
              organizationSlug: selectedOrganizationSlug,
              appId: selectedAppId,
              id: selectedDashboardId,
              overlay: "visualBuilder",
              visualId: id,
            }),
          )
        },
      )
      .catch((error) => {
        setError(error)
      })
  }

  return (
    <Overlay classNames="medium" onClose={handleClose} title="Duplicate graph">
      <div className="c-box text-gray-800">
        <div className="c-box__header">
          <h3>Select dashboard</h3>
        </div>
        <div className="px-5 pt-5 pb-2">
          {error && <ValidationErrors error={error} hideTitle={true} className="narrow" />}
          <AppSelector
            organizationSlug={selectedOrganizationSlug}
            appId={selectedAppId}
            onSelect={(organizationSlugAndAppId) => setOrgAndApp(organizationSlugAndAppId)}
          />
          {appId !== selectedAppId && renderRequiredMetrics(requiredMetrics)}
        </div>
        <div className="px-5 pt-2 pb-5">
          <DashboardSelector
            appId={selectedAppId}
            dashboardId={selectedDashboardId}
            onSelect={(selectedDashboardId) => setDashboardId(selectedDashboardId)}
          />
        </div>
        <div className="p-5 border-t border-gray-200 flex space-x-3">
          <Button disabled={!selectedDashboardId} onClick={handleDuplicate}>
            Duplicate
          </Button>
          <Button onClick={handleClose} color="white">
            Cancel
          </Button>
        </div>
      </div>
    </Overlay>
  )
}

VisualDuplicateOverlay.propTypes = {
  currentPathWithParams: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  visualId: PropTypes.string.isRequired,
}

export default withParamNavigator(VisualDuplicateOverlay)
