import { Button } from "#Root/ui"

import TimeInput from "./TimeInput"

const CalendarContainer = ({ children, handleApply, closeCalendar, timestampRef }) => {
  return (
    <div className="c-dropdown p-0 max-h-full" key="calendar-container">
      <div className="relative p-3">{children}</div>
      {timestampRef && <TimeInput ref={timestampRef} />}
      <div className="flex items-center p-3 border-t border-gray-200 space-x-3">
        <Button onClick={handleApply} size="sm">
          Apply
        </Button>
        <Button onClick={closeCalendar} size="sm" color="white">
          Cancel
        </Button>
      </div>
    </div>
  )
}

CalendarContainer.propTypes = {
  children: PropTypes.node,
  handleApply: PropTypes.func.isRequired,
  closeCalendar: PropTypes.func.isRequired,
  timestampRef: PropTypes.object,
}

export default CalendarContainer
