import { useChartGraphContext } from "../contexts/GraphContext"
import { useChartHoverStateContext } from "../contexts/HoverStateContext"

export const useDisabledLines = (allLines, initialDisabledLines) => {
  const [disabledLines, setDisabledLines] = React.useState(initialDisabledLines)

  const toggleSelectedLine = (line, metaKey) => {
    // Only one line, don't disable it
    if (allLines.length < 2) {
      return
    }

    setDisabledLines((prev) => {
      // One line is disabled, and user clicks the same line without the meta key: do nothing
      if (!metaKey && disabledLines.length == allLines.length - 1 && !prev.includes(line)) {
        return prev
      }

      // One line is disabled,and user clicks the same line, with meta key: enable all
      if (metaKey && disabledLines.length == allLines.length - 1) {
        return []

        // User meta-key clicks any line: disable all but this line
      } else if (metaKey) {
        return allLines.filter((l) => l !== line)

        // User clicks a single disabled line: enable it
      } else if (prev.includes(line)) {
        return prev.filter((l) => l !== line)

        // User clicks a single enabled line: disable it
      } else {
        return [...prev, line]
      }
    })
  }

  const enableAllLines = () => {
    setDisabledLines([])
  }

  return { disabledLines, toggleSelectedLine, enableAllLines }
}

export const useMouseCoordinates = () => {
  const { mouseX, canvasWidth: canvasWidthCheckpoint, mouseY } = useChartHoverStateContext()
  const { canvasWidth, width } = useChartGraphContext()

  // Canvas offset is static no matter what
  const canvasXOffset = width - canvasWidth

  // Let's calculate the resize ratio
  const resizeRatio = canvasWidth / (canvasWidthCheckpoint || canvasWidth)

  // Move the mouse X to the right position
  const realMouseX = mouseX * resizeRatio + canvasXOffset

  return { mouseX: realMouseX || mouseX, mouseY }
}
